import { Basic } from "./Components";
//import logo from "../../images/logo.svg";
import logo from "../../images/logo.png";
import "./index.sass";
import Header from "../../Components/Header";

const LoginPage = ({ login }) => (
  <>
    {!login && <Header />}
    <div className="container loginContainer">
      <div className="logo">
        <img alt="logo" src={logo} />
        {/*<h1>Cvtheque</h1>*/}
      </div>
      <Basic login={login} />
    </div>
  </>
);

export default LoginPage;
