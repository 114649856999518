import "../styles/header.sass";
import { useState, useEffect } from "react";
//import logo from "../images/logo.svg";
import logo from "../images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";

const Header = ({ id }) => {
  const [show, setShow] = useState(false);
  const [profileD, setProfileD] = useState(false);

  const [links, setLinks] = useState([
    {
      id: 1,
      title: "Gestion des candidatures",
      link: "/",
    },
    /*
    {
      id: 2,
      title: "Gestion des offres d'emploi",
      link: "/GestionDesOffres",
    },
    */
    {
      id: 3,
      title: "Statistiques",
      link: "/Statistiques",
    },
  ]);

  const navigate = useNavigate();

  const logout = (e) => {
    e.stopPropagation();
    localStorage.removeItem("token");
    navigate("/login");
  };

  const editProfil = (e) => {
    e.stopPropagation();
    navigate(`/EditerProfil`);
  };

  const getUser = JSON.parse(localStorage.getItem("user")).role;

  useEffect(() => {
    if (getUser === "Admin") {
      const newLink = [
        ...links,
        {
          id: 4,
          title: "Créer un compte",
          link: "/Creer",
        },
      ];
      setLinks(newLink);
    }
    // eslint-disable-next-line
  }, [getUser]);

  return (
    <header>
      <div className="container">
        <NavLink className="logo" to="/">
          <img src={logo} alt="logo"  />
          <h1>CVth&egrave;que</h1>
        </NavLink>

        <nav>
          <i onClick={() => setShow(true)} className="fa-solid fa-bars"></i>
          <ul className={show ? "act mainMenu" : "mainMenu"}>
            <i onClick={() => setShow(false)} className="fa-solid fa-xmark"></i>
            {links.map((e) => (
              <Link key={e.id} title={e.title} link={e.link} />
            ))}
          </ul>
        </nav>

        <div onClick={() => setProfileD((e) => !e)} className="profile">
          {localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")).firstName
            : ""}
          <i className="fa-solid fa-angle-down"></i>
          <ul className={`${profileD ? "act " : ""}dropdown`}>
            <li onClick={editProfil}>Modifier mon profil</li>
            <li onClick={logout}>Se déconnecter</li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;

const Link = ({ link, title }) => (
  <li>
    <NavLink className={({ isActive }) => (isActive ? "act" : "")} to={link}>
      {title}
    </NavLink>
  </li>
);
