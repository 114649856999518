import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import "./styles/index.sass";

const EditerProfil = () => {
  let navigate = useNavigate();
  const [profil, setProfil] = useState({
    title: "",
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    isVerified: "",
    password: "",
    confirmPassword: "",
  });

  const userItem = JSON.parse(localStorage.getItem("user"));
  const id = userItem.id;
  const apiURL = "https://cv-api.shiftin-demo.com/accounts/";
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = () => {
      fetch(`${apiURL}${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setProfil(data);
        });
    };
    fetchData();
  }, [token, id]);

  const updateCandApi = async (id) => {
    const notifySuccess = () =>
      toast.success("Informations sauvegardées avec succès !");

    const notifyError = (msg) =>
      toast.error(`Une erreur est survenue : ${msg}`);

    const profilUpdate = {
      ...profil,
    };

    const res = await fetch(`${apiURL}${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(profilUpdate),
    });

    if (res.status === 200) {
      notifySuccess();
      localStorage.removeItem("token");
      setTimeout(() => navigate("/login"), 2000);
    } else {
      const g = await res.json();
      console.log("Test error", g.message);
      notifyError(g.message);
    }
  };

  const handleInput = (e) => {
    console.log(e.target.name, " : ", e.target.value);
    setProfil({ ...profil, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Header />
      <Toaster />
      <h2>Modifier mon profil</h2>
      <div className="preview0 container editContainer">
        <h3>Informations personnelles</h3>
        <form>
          <div className="grid wrapper editWrapper">
            <div>
              <div className="title">
                <label>
                  Désignation :
                  <input
                    name="title"
                    type="text"
                    defaultValue={profil.title}
                    placeholder="Désignation"
                    onChange={handleInput}
                  />
                </label>
              </div>
              <div className="title">
                <label>
                  Nom :
                  <input
                    name="firstName"
                    type="text"
                    defaultValue={profil.firstName}
                    placeholder="Nom"
                    onChange={handleInput}
                  />
                </label>
              </div>

              <div className="title">
                <label>
                  Mot de passe :
                  <input
                    name="password"
                    type="text"
                    defaultValue={profil.password}
                    autoComplete="off"
                    placeholder="Mot de passe"
                    onChange={handleInput}
                  />
                </label>
              </div>
              <div className="title">
                <label>
                  Rôle :
                  <input
                    name="role"
                    type="text"
                    defaultValue={profil.role}
                    placeholder="Rôle"
                    onChange={handleInput}
                    disabled="disabled"
                    // profil.role === "Admin" ? false : "disabled"
                  />
                </label>
              </div>
            </div>
            <div className="flex-container span-grid-1">
              <div className="flex">
                <div>
                  <div className="title">
                    <label>
                      E-mail :
                      <input
                        name="email"
                        type="email"
                        defaultValue={profil.email}
                        placeholder="E-mail"
                        onChange={handleInput}
                      />
                    </label>
                  </div>
                  <div className="title">
                    <label>
                      Prénom :
                      <input
                        name="lastName"
                        type="text"
                        defaultValue={profil.lastName}
                        placeholder="Prénom"
                        onChange={handleInput}
                      />
                    </label>
                  </div>

                  <div className="title">
                    <label>
                      Confirmation du mot de passe :
                      <input
                        name="confirmPassword"
                        type="text"
                        defaultValue={profil.confirmPassword}
                        placeholder="Confirmation du mot de passe"
                        onChange={handleInput}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="btn-update">
          <button
            className="downloadBtn updateCandidat"
            onClick={() => updateCandApi(id)}
          >
            Sauvegarder les changements
          </button>
        </div>
      </div>

      <footer className="container">
        Données strictement confidentielles / &copy; Tous droits réservés - CM
        Consulting
      </footer>
    </div>
  );
};

export default EditerProfil;
