import React, { useState } from "react";
import Header from "../../Components/Header";
import settingsIcon from "../../images/settings.svg";
import "./styles/index.sass";
import BarChart from "./Components/MyChart";
import Dropdown from "./Components/DropdownOffres";
import AsyncSelect from "react-select/async";

const Statistiques = () => {
  // eslint-disable-next-line
  const [inputValue, setValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState({
    jobTitle: "all",
  });

  // Offre Dropdown changes
  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const fetchOffres = async () => {
    const result = await Dropdown.get("/candidatures/");
    const data = []
    result.data.forEach(e => {
      return data.find(a => a.poste === e.poste) ? null : data.push(e)
    });
    console.log({ data });
    return data;
  };

  return (
    <>
      <Header />
      <h2>Statistiques</h2>
      <div className="statistiques-filter">
        <AsyncSelect
          className="selectEl"
          cacheOptions
          defaultOptions
          placeholder="Filtrer par offre d'emploi..."
          value={selectedValue}
          getOptionLabel={(e) => e.poste}
          getOptionValue={(e) => e.poste}
          loadOptions={fetchOffres}
          onInputChange={handleInputChange}
          onChange={handleChange}
        />
        <img src={settingsIcon} alt="filter" />
        <span> {selectedValue.jobTitle !== 'all' ? selectedValue.jobTitle : "Filter par offre d'emploi..."}</span>
        <i className="fa-solid fa-angle-down"></i>
      </div>
      <BarChart selectedValue={selectedValue} />
    </>
  );
};

export default Statistiques;

