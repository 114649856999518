import { useEffect, useState } from "react";
import "../../../styles/table.sass";
import DataTable, { createTheme } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import EditerCandidature from "../../EditerCandidature";
import { filterFun, getCandidatures } from "../../../utils/table";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";

const Table = ({ filter, setSelected }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [candPerPage, setCandPerPage] = useState(5);

  const apiURL = "https://cv-api.shiftin-demo.com/candidatures/";
  const token = localStorage.getItem("token");
  useEffect(() => {
    setIsLoading(true);
    fetch(apiURL)
      .then((response) => response.json())
      .then((responseData) => {
        responseData.sort((a, b) => a.id > b.id).reverse();
        setResponse(responseData);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getCandidatures(response);
  }, [response]);

  // Get current candidatures
  const indexLastCand = currentPage * candPerPage;
  const indexFirstCand = indexLastCand - candPerPage;

  // Table Data without "trash" Status
  const allValid = filterFun(filter, response).filter(
    (statusFilter) => statusFilter.status !== "trash"
  );

  const toDisplay = allValid.slice(indexFirstCand, indexLastCand);

  // Change page
  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filter, candPerPage]);

  const T = {
    nouvelle: "Nouvelle",
    "en-cours": "En cours",
    preselectionnee: "Présélectionnée",
    validee: "Validée",
    refusee: "Refusée",
  };

  const columns = [
    {
      id: 1,
      name: "Date de réception",
      selector: (row) => moment(row.created).format("DD-MM-YYYY"),
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: false,
      width: "125px",
    },
    {
      id: 2,
      name: "Nom du candidat",
     // selector: (row) => row.nom + " " + row.prenom,
      selector: (row) => row.nom ,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    {
      id: 3,
      name: "E-mail",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      className: "emailField",
    },
    {
      id: 4,
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    {
      id: 5,
      name: "Offre d'emploi",
      selector: (row) => row.poste,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    /*
    {
      id: 5,
      name: "Wilaya",
      selector: (row) => row.ville,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
    },
    */
    {
      id: 6,
      name: "CV",
      selector: (row) => row.cv_html,
      sortable: true,
      reorder: true,
      center: true,
      width: "80px",
    },
    {
      id: 7,
      name: "Status",
      selector: (row) => T[row.status],
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      width: "90px",
    },
    {
      id: 8,
      name: "Mention",
      selector: (row) => row.mention,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      width: "80px",
    },
    {
      id: 9,
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      center: true,
      allowOverflow: true,
      omit: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actions-btn">
          <Link to={`/preview/${row.id}`}>
            <i className="fa-solid fa-eye"></i>
          </Link>
          <Link
            to={`/gestionDesCandidatures/EditerCandidature/${row.id}`}
            onClick={(e) => EditerCandidature(row.id, e)}
          >
            <i className="fa-solid fa-pen"></i>
          </Link>
          <Link to="#!" onClick={(e) => popDelete(row.id, e)}>
            <i className="fa-solid fa-trash-can"></i>
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "150px",
    },
  ];

  const deleteCandidature = async (id, e) => {
    const notifySuccess = () =>
      toast.success("La candidature a bien été supprimée !");

    const notifyError = (msg) =>
      toast.error(`Une erreur est survenue : ${msg}`);

    e.preventDefault();
    const statusUpdate = {
      ...response.find((e) => e.id === id),
      status: "trash",
    };
    delete statusUpdate.cv_html;

    const res = await fetch(`${apiURL}${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(statusUpdate),
    });

    if (res.status === 200) {
      setResponse(response.filter((e) => e.id !== id));
      notifySuccess();
    } else {
      const g = await res.json();
      console.log("Test error", g.message);
      notifyError(g.message);
    }
  };

  const popDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <div className="deleteWindow">
              <h3>Supprimer la candidature ?</h3>
              <p>
                Etes-vous sûr de vouloir supprimer la candidature ? Cette action
                est definitive. Les statistiques reliées à la candidature
                restent valables.
              </p>
              <div className="actions">
                <a href="#!" className="cancel" onClick={onClose}>
                  Annuler
                </a>
                <a
                  href="!#"
                  className="delete"
                  onClick={(e) => {
                    deleteCandidature(id, e);
                    onClose();
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                  Supprimer
                </a>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  createTheme(
    "cmc",
    {
      background: {
        default: "#fff",
      },
      text: "#00358c",
    },
    "light"
  );

  return (
    <div className="tableContainer">
      <div className="mainTable">
        <Toaster />
        <DataTable
          theme="cmc"
          columns={columns}
          progressPending={isLoading}
          data={toDisplay}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          selectableRows
          onSelectedRowsChange={({ selectedRows }) => setSelected(selectedRows)}
          noDataComponent="Votre requête n’a retourné aucun résultat"
        />
        <Pagination
          candPerPage={candPerPage}
          totalCand={allValid.length}
          paginate={paginate}
          currentPage={currentPage}
          setCandPerPage={setCandPerPage}
        />
      </div>
    </div>
  );
};

// export function handleRowChange({ selectedRows }) {
//   console.log(selectedRows);
// }

export default Table;
